<template lang="pug">
  .product-dropdowns
    //- dropdowns...
    template(v-for="(slice, i) in slices", v-if="slice.slice_type === 'accordion_section'")
      div(:class="{'border-b': i === slices.length - 1}")
        product-dropdown(:slice="slice", :loadOpen="i === 0 && slices.length > 1")
</template>

<script>
import ProductDropdown from '@/components/ProductDropdown'
export default {
  name: 'ProductDropdowns',
  components: { ProductDropdown },
  props: {
    slices: { type: Array, default: () => ([]) }
  }
}
</script>
