<template lang="pug">
  dropdown(ref="dropdown", v-bind="$attrs", :clipped="isClipped")
    //- heading
    .h-51.border-t.flex.items-center.justify-center.-mb-px(slot="summary")
      prismic-rich-text.font-medium.text-12.md-text-14(:field="slice.primary.heading")
    //- expanding text
    prismic-rich-text.text-12.md-text-14(:field="slice.primary.text", :class="{'dropdown-text--clipped': isClipped}")
    button.text-12.md-text-14.text-gray-500.font-medium(v-show="isClipped", @click="expandText") Read more

</template>
<script>
import Dropdown from '@/components/Dropdown'
export default {
  name: 'ProductDropdown',
  components: { Dropdown },
  props: {
    slice: { type: Object, default: () => ({}) }
  },
  data () {
    return {
      isClipped: null
    }
  },
  methods: {
    async expandText () {
      this.isClipped = false
      await this.$nextTick()
      this.$refs.dropdown.expand()
    }
  },
  mounted () {
    this.isClipped = this.$prismic.richTextAsPlain(this.slice.primary.text).length > 100
  }
}
</script>

<style>
.dropdown-text--clipped {
  height: 4.85em;
  overflow: hidden;
}
</style>
