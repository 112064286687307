<template lang="pug">
  article.product.px-24.md-px-0.pt-72.md-pt-0
    template(v-if="doc")

      //- (mobile first image)
      .product__mobile-first-image.md-hidden
        template(v-for="(slice, i) in doc.data.images.slice(0, 1)")
          //- (product image)
          media.md-hidden(v-if="slice.slice_type === 'product_image' && slice.primary.image.url", @click.native="openViewer(slice)")
            resp-img(slot="media", :src="slice.primary.image.url", :alt="slice.primary.image.alt", @click="openViewer(slice)")

      //- body (landscape: multi-column layout)
      .flex.flex-col.md-flex-row.items-start.relative
        //- info column
        section.md-w-1x3.md-sticky.top-0.left-0.md-px-24.lg-px-72.pointer-events-none
          //- non-padded content column for overflow scroll (cursor) area
          .pointer-events-auto.md-pt-50vh.md-max-h-screen.md-overflow-y-scroll.scrollbars-hidden
            header.w-full
              //- title
              h1.italic.font-medium.text-center.text-16.mb-8 {{ $prismic.richTextAsPlain(doc.data.title) }}

            //- description
            prismic-rich-text.italic.text-center.mb-32.text-12.md-text-14(:field="doc.data.description")

            //- (landscape dropdowns)
            product-dropdowns.hidden.md-block(:slices="doc.data.body")

            //- (landscape gradient for scroll overflow)
            .hidden.md-block.sticky.bottom-0.left-0.w-full.h-52.bg-gradient-to-b.from-transparent.to-white.pointer-events-none

        //- images column
        section.order-last.md-order-none.md-flex-1.md-pt-144
          //- slices...
          template(v-for="(slice, i) in doc.data.images")
            //- (product image)
            media(v-if="slice.slice_type === 'product_image' && slice.primary.image.url", :class="{'hidden md-block': i === 0}")
              //- image
              resp-img.cursor-expand(@click.native="openViewer(slice)", slot="media", :src="slice.primary.image.url", :alt="slice.primary.image.alt")
              //- caption
              prismic-rich-text.inline.children-inline(slot="caption", :field="slice.primary.caption")

        //- options/buy column
        aside.md-w-1x3.md-sticky.top-0.left-0.md-px-24.lg-px-72.md-pt-50vh.md-pb-52
          form(v-if="shopifyProduct", @submit.prevent)
            //- options...
            .flex.flex-wrap.-mx-5(v-for="(option, i) in shopifyProduct.options")
              //- radio buttons
              label.cursor-pointer.block.px-5(v-for="(value, ii) in option.values", style="flex:1 0 50%")
                .flex.border-b.items-center.justify-center.pb-12.font-medium(:class="[variantSelected && variantSelected.title === value ? ('border-black') : 'text-gray-500']")
                  input(ref="option", type="radio", :name="option.name", :value="value", @input="onOptionSelect")
                  span.text-12.md-text-14(:class="{dot: variantSelected && variantSelected.title === value}") {{ value }}

            //- (selected variant)
            .flex.w-full.justify-between.text-12.md-text-15.py-12.normal-nums(v-if="variantSelected")
              //- price
              div ${{ variantSelected.priceV2.amount }} {{ variantSelected.priceV2.currencyCode }}
              //- inventory
              div.text-gray-600 {{ variantSelected.quantityAvailable }} left

            //- buy button
            button.rounded-lg.h-44.flex.items-center.justify-center.w-full.bg-black.text-white.font-medium.text-12.md-text-15(:disabled="!variantIsAvailable", @click="addToBag")
              template(v-if="status") {{ status }}
              template(v-else-if="variantIsAvailable") Add to Bag
              template(v-else) Not Available

            //- (mobile dropdowns)
            product-dropdowns.my-24.md-hidden(:slices="doc.data.body")

      //- Editions index
      observer.pt-144.project__index.h-75vh.blur-media.pointer-events-none(:threshold="0.9", @visible="toIndex = true", @hidden="toIndex = false", :class="{'overflow-hidden': !leaving}")
        editions

      //- (product image viewer)
      .fixed.overlay.z-50.bg-white.cursor-x-icon.overflow-y-scroll.overflow-x-scroll(v-if="viewer", @click="closeViewer")
        media(:flush="true", :noPadding="true")
          //- resp-img(v-if="image", :bg="true", :winLngth="1", :useHeight="isPortrait", :src="image.url", :alt="image.alt", :key="image.url", @loaded="onLoaded")
          resp-img.overflow-x-scroll.scrollbars-hidden(slot="media",:mobileFull="true", :src="viewer.primary.image.url", :alt="viewer.primary.image.alt")
          //- caption
          prismic-rich-text.w-full.text-center.fixed.bottom-0.z-10.mb-20.inline.children-inline(slot="caption", :field="viewer.primary.caption")

</template>

<script>
import store from '@/store'
import Vue from 'vue'
import Media from '@/components/Project/Media'
import RespImg from '@/components/RespImg'
import Observer from '@/components/IntersectionObserver'
import Editions from '@/views/Editions'
import ProductDropdowns from '@/components/Product/ProductDropdowns'
import ShopFooterLinks from '@/components/ShopFooterLinks'
const vue = new Vue()
let doc
export default {
  name: 'Product',
  components: { Media, RespImg, ProductDropdowns, Observer, Editions, ShopFooterLinks },
  data () {
    return {
      doc,
      shopifyProduct: undefined,
      variantSelected: null,
      status: null,
      viewer: null,
      toIndex: false,
      leaving: false
    }
  },
  computed: {
    variantIsAvailable () {
      return this.variantSelected?.availableForSale
    }
  },
  beforeRouteEnter: (to, from, next) => getProductFromPrismic(to, from, next),
  beforeRouteUpdate: (to, from, next) => getProductFromPrismic(to, from, next),
  async created () {

  },
  async mounted () {
    // for dev hot reload...
    this.doc = await getProductFromPrismic(this.$route, null, () => {})
    this.getShopifyProduct()
    this.$store.state.bus.$on('newScrollY', this.onScroll)
  },
  methods: {
    async getShopifyProduct () {
      if (this.doc) {
        this.shopifyProduct = await this.$store.dispatch('shop/getProduct', this.doc.data.shopify_product.handle)
        // select default variant
        await this.$nextTick()
        return this.$refs.option[0]?.click()
      }
    },
    onOptionSelect (e) {
      const matchedVariant = this.shopifyProduct.variants.edges.find(({ node }) => {
        return node.selectedOptions.find(option => option.name === e.target.name && option.value === e.target.value)
      })
      this.variantSelected = matchedVariant?.node
    },
    async addToBag () {
      // !! one add at a time
      if (this.status) return
      // add...
      try {
        this.status = 'Adding...'
        await this.$store.dispatch('shop/addItemToCheckout', { variantId: this.variantSelected.id, quantity: 1, customAttributes: [{ key: 'description', value: this.$prismic.richTextAsPlain(this.doc.data.description) }] })
        this.status = 'Added'
      } catch (e) {
        // error
        console.error(e)
        this.status = 'Error'
      }
      // reset status
      setTimeout(() => { this.status = null }, 2000)
    },
    openViewer (slice) {
      this.viewer = slice
      // prevent main scroll
      document.body.style.overflow = 'hidden'
    },
    closeViewer () {
      this.viewer = null
      // restore scroll
      document.body.style.overflow = ''
    },
    onScroll () {
      clearTimeout(this.afterScroll)
      this.afterScroll = setTimeout(() => {
        if (!this.toIndex) return
        this.leaving = true
        this.$nextTick(() => this.$emit('onEndTo', 'editions'))
      }, 1000)
    }
  }
}

const getProductFromPrismic = async (to, from, next) => {
  try {
    store.commit('PROGRESS_START')

    // fetch...
    doc = doc || await vue.$prismic.client.getByUID('product', to.params.uid)

    if (!doc) return store.commit('PROGRESS_FAIL') // TODO - maybe router.push 404 ?
    store.commit('PROGRESS_FINISH')

    next()
    return doc
  } catch (e) {
    console.error(e)
    next()
  }
}

</script>

<style scoped>
input[type=radio] {
  display: none;
}
.dot::before {
  content: url('../components/Icons/Editions-Bullet.svg');
  margin-right: .7rem;
  margin-left: -1.7rem
}
.cursor-x-icon {
  cursor: url('../assets/icons/cursor-x.svg'), auto;
}

</style>
