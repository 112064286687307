<template lang="pug">
  .dropdown(:class="{'dropdown--open': expanded}", aria-role="group")
    //- summary
    .relative.select-none.cursor-pointer(@click.prevent="toggle")
      slot(name="summary")

      //- (icon: +/x)
      .absolute.top-0.right-0.h-full.flex.items-center
        svg-x.w-10.h-10.text-gray-250.transform.transition.duration-150.origin-center(:class="{'-rotate-45': !expanded}", width="1")

      //- (icon: v/^)
      //- svg-chevron-down._transform(v-else-if="icon.includes('chevron')", :class="{'_rotate-180': expanded, '_mr-6': icon.includes('inset')}")

    //- body
    .overflow-hidden(:style="{transition: `max-height ${speed}ms`, maxHeight: expanded ? `${bodyHt}px` : 0}")
      div.pb-24(ref="body")
        slot
</template>

<script>
// TODO - use ResizeObserver to recalc body ?
// import SvgChevronDown from '@/components/SvgChevronDown'
// import SvgPlusX from '@/components/SvgPlusX'
import svgX from '@/components/Icons/SVG-X'

export default {
  name: 'Dropdown',
  components: { svgX },
  props: {
    speed: { type: String, default: '250' },
    loadOpen: Boolean,
    icon: { type: String, default: 'plus' },
    isClipped: Boolean
  },
  data () {
    return {
      expanded: false,
      bodyHt: '0'
    }
  },
  methods: {
    toggle () {
      return !this.expanded ? this.expand() : this.collapse()
    },
    expand () {
      // (re)calc body before animation
      this.bodyHt = this.$refs.body.offsetHeight

      this.$nextTick(() => {
        this.expanded = true
      })
    },
    collapse () {
      this.expanded = false
    }
  },
  mounted () {
    this.expanded = this.loadOpen
    if (this.expanded) this.expand()
  }
}
</script>

<style lang="postcss">
/* details.dropdown > summary {
  list-style: none;
  &::-webkit-details-marker { display: none; }
  &:focus{ outline:none; }
} */
</style>
